/*
  (zoqui) - adapted from: https://tahazsh.com/detect-outside-click-in-vue
  */
import { eventMatchesSelector } from "@/utils";

let handlers = new Map(),
  clickCallbacks = new Map(),
  keyupCallbacks = new Map();

function updateSettingsFor(el, binding, vnode) {
  const { handler, allowed } = binding.value;

  let uid = el.dataset.closableUID;
  if (!uid) {
    // creates random unique number to identify element
    uid = Math.round(Math.random() * 1000);
    el.dataset.closableUID = uid;
  }
  // [handler can be a method name present in vnode or a function itself]
  // adds handler to map with key = function name and value = function
  let key = (handler.name || handler) + "-" + uid;
  let value = vnode.context[handler] || handler;
  handlers.set(key, value);

  // remove previous added handler if new one is different
  let dupKey;
  for (let k of handlers.keys()) {
    if (k.endsWith(uid) && k != key) dupKey = k;
  }
  if (dupKey) handlers.delete(dupKey);

  el.dataset.closable = JSON.stringify({ handler: key, allowed });
}

function getOptions(el) {
  try {
    let options = JSON.parse(el.dataset.closable);
    return options;
  } catch (e) {
    return {};
  }
}

export default {
  bind(el, binding, vnode) {
    updateSettingsFor(...arguments);

    clickCallbacks.set(el, (e) => {
      const { handler, allowed } = getOptions(el);
      let isAllowed = false; // isAllowed element (allowed)

      for (let i in allowed || []) {
        if (vnode.context.$refs[allowed[i]]) {
          isAllowed = vnode.context.$refs[allowed[i]].contains(e.target);
        } else if (eventMatchesSelector(e, allowed[i])) {
          isAllowed = true;
        }
        if (isAllowed) break;
      }
      if (!el.contains(e.target) && !isAllowed) {
        handlers.get(handler)?.("cancel");
      }
    });

    keyupCallbacks.set(el, (e) => {
      const { handler } = getOptions(el);

      if (e.code == "Escape" && handler in vnode.context) {
        handlers.get(handler)("cancel");
      }
    });

    document.addEventListener("click", clickCallbacks.get(el));
    document.addEventListener("keyup", keyupCallbacks.get(el));
  },
  update: updateSettingsFor,
  unbind(el) {
    document.removeEventListener("keyup", clickCallbacks.get(el));
    document.removeEventListener("click", keyupCallbacks.get(el));

    clickCallbacks.delete(el);
    keyupCallbacks.delete(el);
    delete el.dataset.closableUID;
    delete el.dataset.closable;
  }
};
