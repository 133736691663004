<template>
  <div class="color-type">
    <span class="name">
      <slot>
        {{ name }}
      </slot>
    </span>
    <input v-model="modelColor" class="value text-center" />
    <span class="name">
      <span class="btn btn-xs" @click.stop.prevent="copy" :title="$t('copy')">
        <i class="fa fa-copy" style=""></i>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    }
  },
  computed: {
    modelColor: {
      get() {
        return this.color;
      },
      set(val) {
        this.$emit("inputColor", val);
      }
    }
  },
  methods: {
    copy() {
      this.$utils.toClipboard(this.modelColor);
    }
  }
};
</script>

<style lang="scss">
.color-type {
  display: flex;
  margin-top: 8px;
  font-size: 12px;
  .name {
    width: 60px;
    height: 30px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    background: #252930;
  }
  .value {
    flex: 1;
    height: 30px;
    min-width: 100px;
    padding: 0 12px;
    border: 0;
    color: #fff;
    background: #2e333a;
    box-sizing: border-box;
  }
}
</style>
